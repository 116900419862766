import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Covers from "../components/strapi/accessories/Covers";
import Craftsmanship from "../components/strapi/structure/Craftsmanship";
import AccessCare from "../components/strapi/accessories/AccessCare";
import Outropiggy from "../components/strapi/structure/Outropiggy";

export default function Accessories({ data }) {
  return (
    <Layout>
      <SEO title="Covers & Accessories" />
      {data.allStrapiCoversAccessories.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.ca_mh.mh_title}
            mhsubtitle={node.ca_mh.mh_subtitle}
            mhbackground={node.ca_mh.mh_bg.publicURL}
          />
          <Covers />
          <Craftsmanship
            title={node.access_craftsmanship.craft_title}
            description={node.access_craftsmanship.craft_description}
          />
          <AccessCare />
          <Outropiggy outro={node.access_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Accessories {
    allStrapiCoversAccessories {
      edges {
        node {
          id # id of the node
          ca_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          access_craftsmanship {
            id
            craft_title
            craft_description
          }
          access_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`;
